/* eslint-disable radix */
/* eslint-disable arrow-body-style */
import { Avatar, Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import ErrorBoundry from '../alerts/ErrorBoundary';
import Iconify from '../iconify/Iconify';
import { isRestaurantOpen } from '../../config';
import { CartContext } from '../../context/cartProvider';
import EmptyCart from '../../lottie/empty_cart_lottie.json';
import { fShortenNumber } from '../../utils/formatNumber';
import CartDialog from '../dialog/CartDialog';
import { TrackGoogleAnalyticsEvent } from '../../google-analytics';
import GuestUserDialog from '../dialog/GuestUserDialog';
import { AddressContext } from '../../context/AddressProvider';
import { getMinimumOrderAmount } from '../../utils/firebase/FirebaseCommon';
import { getLocalStorage } from '../../utils/SecuredLocalStorage';
import MapDialog from '../dialog/MapDialog';
import { findBranchNameByPostalCode } from '../../utils/commonFunction';

const Tab = ({ handleClick, img, title, timings, isActive, Style }) => (
  <button
    type="button"
    onClick={handleClick}
    className={`flex items-center justify-center gap-1 px-4  text-black ${isActive ? 'bg-white shadow-sm' : 'hover:bg-primary-bg '} ${Style}`}>
    <img src={img} alt={title} className="h-8 w-auto object-cover" />
    <div className="flex flex-col gap-1">
      <h4 className="text-sm font-medium leading-[.50rem]">{title}</h4>
      <p className="text-xs  leading-[.50rem]">{timings} min</p>
    </div>
  </button>
);

const Cart = ({ ...props }) => {
  const navigate = useNavigate();
  const {
    cartItems,
    addToCart,
    deliveryType,
    setDeliveryType,
    clearCart,
    updateQuantity,
    applyCoupon,
    calculateFinalTotalPrice,
    calculateTotalPrice: calculateOriginalPrice,
    calculateTotalQuantity,
  } = useContext(CartContext);
  const { addressInfo } = useContext(AddressContext);

  const [showGuestDialog, setShowGuestDialog] = useState(false);
  const [showMapDialog, setShowMapDialog] = useState(false);
  const [proceedCheckout, setProceedCheckout] = useState(false);

  const decrementQuantity = itemId => updateQuantity(itemId, -1);
  const handleToPaymentProcessClick = async () => {
    const user = getLocalStorage('user');
    if (!user) {
      setShowGuestDialog(true);
      return;
    }
    if (!addressInfo && deliveryType === 'LIEFERUNG') {
      window.scrollTo(0, 0);
      setShowMapDialog(true);
      props.handleAlert({
        open: true,
        message: 'Bitte geben Sie Ihre Adresse ein',
        type: 'info',
      });
      return;
    }
    if (!isRestaurantOpen(findBranchNameByPostalCode(addressInfo?.postalCode).name)) {
      props.handleAlert({
        open: true,
        message: 'Verzeihung! Wir sind im Moment nicht erreichbar',
        type: 'info',
      });
      return;
    }
    const minOrderValue = await getMinimumOrderAmount(addressInfo?.postalCode);
    console.log('minOrderValue', minOrderValue);
    const roundedMinOrderValue = Math.ceil(minOrderValue);
    if (calculateFinalTotalPrice() <= minOrderValue) {
      props.handleAlert({
        open: true,
        message: `Der Mindestbestellpreis beträgt ${roundedMinOrderValue} € – fügen Sie weitere Artikel hinzu!`,
        type: 'info',
      });
      return;
    }
    // eslint-disable-next-line no-unused-expressions
    props?.isMobile && props.setCheckout(true);
    TrackGoogleAnalyticsEvent('cart', 'proceed_checkout', JSON.stringify(cartItems));
    navigate('/checkout');
    // setProceedCheckout(true);
  };
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={{ xs: 'start', md: 'center' }}
        sx={{
          display: {
            xs: 'none',
            lg: 'flex',
          },
        }}>
        <Typography variant="h4" gutterBottom>
          Warenkorb
        </Typography>
      </Stack>
      <Grid className="px-4">
        <div className="hidden gap-1 rounded-[25px] bg-primary-bg px-1 py-1 md:grid md:grid-cols-2">
          <Tab
            title={'Lieferung'}
            timings={'25'}
            handleClick={() => setDeliveryType('LIEFERUNG')}
            img={'/assets/diazo_delivery.png'}
            isActive={deliveryType === 'LIEFERUNG'}
            Style={'rounded-t-[25px] md:rounded-[25px]'}
          />
          <Tab
            title={'Abholen'}
            timings={'15'}
            handleClick={() => setDeliveryType('ABHOLEN')}
            img={'/assets/diazo_bag.png'}
            isActive={deliveryType === 'ABHOLEN'}
            Style={'rounded-b-[25px] md:rounded-[25px]'}
          />
        </div>
      </Grid>
      <Stack
        p={1}
        className="overflow-y-auto"
        sx={{
          width: '100%',
          maxHeight: cartItems.length === 0 ? '100%' : 'calc(100vh - 400px)',
        }}>
        {cartItems.length === 0 ? (
          <Stack spacing={2}>
            <Lottie animationData={EmptyCart} loop={Boolean(true)} style={{ height: 150 }} />
            <Stack textAlign={'center'}>
              <Typography variant="subtitle1">lhr Warenkorb ist leer 😕</Typography>
              <Typography variant="body2">
                Diene Bestellung ist ohne Artikel ganz einsam. Such dir was Schönes aus!
              </Typography>
            </Stack>
          </Stack>
        ) : (
          <>
            {cartItems?.map((item, i) => (
              <Box className="h-full w-full" key={i}>
                <Box sx={{ padding: 1, my: 0.5 }}>
                  <Grid container>
                    <Grid
                      item
                      xs={9.5}
                      display={'flex'}
                      flexDirection={'column'}
                      justifyContent={'space-between'}
                      py={2}>
                      <Box ml={1} display={'flex'} flexDirection={'row'} gap={1} justifyContent={'start'}>
                        <Box component={'img'} src={item.itemImages[0]} alt={''} className="h-10 w-10 rounded-lg" />
                        <Box display={'flex'} flexDirection={'column'} justifyContent={'start'}>
                          <Typography variant="subtitle1">
                            {item.name} ({item?.sizes?.size}){' '}
                          </Typography>{' '}
                          <Typography sx={{ ml: 1 }} variant="h6">
                            <span className={`${item.points && 'line-through'}`}>
                              {fShortenNumber(item.sizes?.price)}
                            </span>{' '}
                            € {item.points && `- ${item.points} Punkte`}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={2.5}
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}>
                      <Iconify
                        icon={'mingcute:delete-line'}
                        onClick={() => decrementQuantity(item)}
                        className="hover:cursor-pointer"
                      />
                      <Typography variant="subtitle1" fullWidth>
                        {item.quantity}
                      </Typography>
                      {!item.points && (
                        <Iconify icon={'typcn:plus'} onClick={() => addToCart(item)} className="hover:cursor-pointer" />
                      )}
                    </Grid>
                  </Grid>
                </Box>
                {i !== cartItems.length - 1 && <Divider />}
              </Box>
            ))}
            <Button onClick={() => clearCart()} sx={{ mt: 2, textTransform: 'none' }} variant="outlined">
              Warenkorb leeren
            </Button>
          </>
        )}
      </Stack>

      {cartItems.length !== 0 && (
        <>
          <Stack my={2} className="md:hidden">
            <Grid container spacing={2} mt={0}>
              <Grid item xs={6}>
                <button
                  type="button"
                  onClick={() => setDeliveryType('LIEFERUNG')}
                  className={`${deliveryType === 'LIEFERUNG' && 'bg-[#C4CDD5]'} flex items-center justify-center gap-2 rounded-md border border-black px-4 py-1.5 text-sm font-bold hover:bg-[#C4CDD5]`}>
                  <Box component={'img'} src="/assets/diazo_delivery.png" alt="delivery" height={30} width={30} />
                  Lieferung
                </button>
              </Grid>
              <Grid item xs={6}>
                <button
                  type="button"
                  onClick={() => setDeliveryType('ABHOLEN')}
                  className={`${deliveryType === 'ABHOLEN' && 'bg-[#C4CDD5]'} flex items-center justify-center gap-2 rounded-md border border-black px-4 py-1.5 text-sm font-bold hover:bg-[#C4CDD5]`}>
                  <Box component={'img'} src="/assets/diazo_bag.png" alt="pickup" height={30} width={30} />
                  Abholung
                </button>
              </Grid>
            </Grid>
          </Stack>
          <Grid
            container
            spacing={{
              xs: 1,
              md: 2,
            }}
            p={2}>
            <Grid item xs={6}>
              <Typography variant="subtitle1" fullWidth>
                Zwischensumme:
              </Typography>
            </Grid>
            <Grid item xs={6} display={'flex'} justifyContent={'end'}>
              <Typography variant="subtitle1" fullWidth>
                {fShortenNumber(calculateOriginalPrice())} €
              </Typography>
            </Grid>
            {deliveryType === 'LIEFERUNG' && (
              <>
                <Grid item xs={6}>
                  <Typography variant="body2" fullWidth>
                    Liefergebuhr:
                  </Typography>
                </Grid>
                <Grid item xs={6} display={'flex'} justifyContent={'end'}>
                  <Typography variant="body2" fullWidth>
                    {fShortenNumber(0)} €
                  </Typography>
                </Grid>
              </>
            )}

            {applyCoupon.show && (
              <>
                <Grid item xs={6}>
                  <Typography variant="body2" fullWidth>
                    Rabatt :
                  </Typography>
                </Grid>
                <Grid item xs={6} display={'flex'} justifyContent={'end'}>
                  <Typography variant="body2" fullWidth>
                    {fShortenNumber(10)} €
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" fullWidth>
                Gesamt:
              </Typography>
            </Grid>
            <Grid item xs={6} display={'flex'} justifyContent={'end'}>
              <Typography variant="subtitle1" fullWidth>
                {fShortenNumber(calculateFinalTotalPrice())} €
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div className="sticky bottom-0">
                <Button
                  variant="contained"
                  fullWidth
                  disabled={calculateTotalQuantity() === 0}
                  onClick={handleToPaymentProcessClick}
                  sx={{
                    'backgroundColor': '#1b1b1b',
                    '&:hover': {
                      backgroundColor: '#1b1b1b',
                    },
                  }}
                  startIcon={
                    <Avatar
                      sx={{
                        width: 30,
                        height: 30,
                        bgcolor: theme => theme.palette.common.white,
                        color: theme => theme.palette.common.black,
                      }}>
                      <Typography variant="subtitle1" fullWidth>
                        {calculateTotalQuantity()}
                      </Typography>
                    </Avatar>
                  }>
                  <Box
                    width={'100%'}
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-around'}>
                    <Typography variant="subtitle1">Zum Bezahlvorgang</Typography>
                    <Divider
                      orientation="vertical"
                      variant={'fullWidth'}
                      flexItem
                      sx={{
                        borderColor: {
                          xs: '#fff',
                          md: '#1b1b1b',
                        },
                        paddingX: {
                          xs: 1,
                          md: 0,
                        },
                      }}
                    />
                    <Typography variant="subtitle1"> {fShortenNumber(calculateFinalTotalPrice())} €</Typography>
                  </Box>
                </Button>
              </div>
            </Grid>
          </Grid>
        </>
      )}
      {proceedCheckout && <CartDialog open={proceedCheckout} handleClose={() => setProceedCheckout(false)} />}
      {showGuestDialog && <GuestUserDialog open={showGuestDialog} handleClose={() => setShowGuestDialog(false)} />}
      {showMapDialog && <MapDialog open={showMapDialog} handleClose={() => setShowMapDialog(false)} />}
    </>
  );
};

export default ErrorBoundry(Cart);
