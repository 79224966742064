/* eslint-disable global-require */
/* eslint-disable camelcase */
import { Button, Card, Stack } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import parse from 'autosuggest-highlight/parse';
import React, { useContext, useRef, useState } from 'react';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import { collection, doc, firestore, getDoc, updateDoc } from '../../firebaseconfig';
import UnavailableDialog from '../dialog/UnavailableDialog';
import ErrorBoundry from '../alerts/ErrorBoundary';
import { extractPostalCodeFromAddress } from '../../utils/commonFunction';
import { getLocalStorage } from '../../utils/SecuredLocalStorage';
import { AddressContext } from '../../context/AddressProvider';
import { diazoLocations } from '../../constants/DiazoOutletInfo';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

// const markerIcon = `
//   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
//     <path d="M12 2C7.48 2 4 5.48 4 10.17C4 15.1 11.42 22 11.42 22C11.42 22 19 15.1 19 10.17C19 5.48 15.52 2 11 2ZM11 12C9.34 12 8 10.66 8 9C8 7.34 9.34 6 11 6C12.66 6 14 7.34 14 9C14 10.66 12.66 12 11 12Z" fill="red" />
//   </svg>
// `;

function GoogleMaps({ showField, handleClose, ...props }) {
  const {setAddressInfo } = useContext(AddressContext);
  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        const service = new window.google.maps.places.AutocompleteService();
        service.getPlacePredictions(request, callback);
      }, 400),
    []
  );
  const newUserAddress = useRef({
    formattedAddress: '',
    latitude: 0,
    longitude: 0,
    country: '',
    postalCode: '',
    city: '',
    street: '',
    houseNumber: '',
  });
  const uiRefresh = useState(-1)[1];
  const [latitude, setLatitude] = React.useState(props.latitude);
  const [longitude, setLongitude] = React.useState(props.longitude);
  const [permanentMarkerPosition] = React.useState([
    { lat: 52.54094480000001, lng: 13.3703954 },
    { lat: 52.4671824, lng: 13.4366736 },
    { lat: 52.5460261, lng: 13.4142707 },
    { lat: 52.548636901812095, lng: 13.208540196539603 },
    { lat: 52.61933324347163, lng: 13.415523270134825 },
    { lat: 52.483846655492634, lng: 13.361593879135514 },
  ]);

  const [value, setValue] = React.useState(props.value ?? null);
  const [user, setUser] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [notFound, setNotFound] = React.useState(false);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });

  const CheckAddressValidity = async addressComponents => {
    const hasCountry = 'country' in addressComponents;
    const hasPostalCode = 'postal_code' in addressComponents;
    const hasStreetNumber = 'street_number' in addressComponents;
    if (hasCountry && addressComponents.country !== 'Germany') {
      props.handleAlert({ open: true, message: 'Please enter your address of germany', type: 'error' });
      return false;
    }
    if (!hasPostalCode) {
      props.handleAlert({ open: true, message: 'Please enter your address with postal code', type: 'error' });
      return false;
    }
    if (!hasStreetNumber) {
      props.handleAlert({ open: true, message: 'Please enter your address with street number', type: 'error' });
      return false;
    }
    return true;
  };
  const isServiceAvailable = description => {
    const selectedPostalCode = extractPostalCodeFromAddress(description);
    if (!diazoLocations.includes(selectedPostalCode)) {
      return false;
    }
    return true;
  };

  // Needs to be Modified
  const handleAutocompleteChange = async (event, newValue) => {
    if (newValue) {
      const { description } = newValue;
      const results = await getGeocode({ address: description });
      const { lat, lng } = await getLatLng(results[0]);
      const { formatted_address, address_components } = results[0];
      setLatitude(lat);
      setLongitude(lng);
      setOptions(newValue ? [newValue, ...options] : options);
      const userAddressComponents = address_components.reduce((acc, component) => {
        const type = component.types[0];
        acc[type] = component.long_name;
        return acc;
      }, {});
      if (CheckAddressValidity(userAddressComponents)) {
        setValue({ description: formatted_address });
        newUserAddress.current = {
          formattedAddress: formatted_address,
          latitude: lat,
          longitude: lng,
          country: userAddressComponents.country,
          postalCode: userAddressComponents.postal_code,
          city: userAddressComponents.locality,
          street: userAddressComponents.route,
          houseNumber: userAddressComponents.street_number,
        };
        uiRefresh(Date.now());
      }
    }
  };
  // Needs to be Modified
  const handleMarkerDragEnd = async event => {
    const newLatitude = event.latLng.lat();
    const newLongitude = event.latLng.lng();
    setLatitude(newLatitude);
    setLongitude(newLongitude);
    const results = await getGeocode({ location: { lat: newLatitude, lng: newLongitude } });
    const { formatted_address, address_components } = results[0];
    setInputValue(formatted_address);
    const userAddressComponents = address_components.reduce((acc, component) => {
      const type = component.types[0];
      acc[type] = component.long_name;
      return acc;
    }, {});
    if (CheckAddressValidity(userAddressComponents)) {
      setValue({ description: formatted_address });
      newUserAddress.current = {
        formattedAddress: formatted_address,
        latitude: newLatitude,
        longitude: newLongitude,
        country: userAddressComponents.country,
        postalCode: userAddressComponents.postal_code,
        city: userAddressComponents.locality,
        street: userAddressComponents.route,
        houseNumber: userAddressComponents.street_number,
      };
      uiRefresh(Date.now());
    }
  };

  React.useEffect(() => {
    let active = true;
    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }
    fetch({ input: inputValue }, results => {
      if (active) {
        let newOptions = [];
        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });
    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  React.useEffect(() => {
    const userData = getLocalStorage('user');
    const userAddress = getLocalStorage('userAddress');
    if (!props?.from) {
      setLongitude(userAddress?.userLongitude);
      setLatitude(userAddress?.userLatitude);
    }
    setUser(userData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps</div>;

  return (
    <>
      <GoogleMap
        center={{ lat: latitude, lng: longitude }}
        zoom={10}
        mapContainerStyle={{ height: '100%', width: '100%' }}>
        {showField && (
          <Stack display={'flex'} alignItems={'center'}>
            <Autocomplete
              id="google-map-demo"
              sx={{ width: { xs: 250, md: 600 }, mt: { xs: 10, md: 2 } }}
              getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
              filterOptions={x => x}
              options={options}
              autoComplete
              includeInputInList
              filterSelectedOptions
              value={value}
              noOptionsText="No locations"
              onChange={handleAutocompleteChange}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={params => <TextField {...params} fullWidth />}
              renderOption={(props, option) => {
                const matches = option.structured_formatting.main_text_matched_substrings || [];

                const parts = parse(
                  option.structured_formatting.main_text,
                  matches.map(match => [match.offset, match.offset + match.length])
                );

                return (
                  <li {...props}>
                    <Grid container alignItems="center">
                      <Grid item sx={{ display: 'flex', width: 44 }} />
                      <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                        {parts.map((part, index) => (
                          <Box key={index} component="span" sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
                            {part.text}
                          </Box>
                        ))}

                        <Typography variant="body2" color="text.secondary">
                          {option.structured_formatting.secondary_text}
                        </Typography>
                      </Grid>
                    </Grid>
                  </li>
                );
              }}
            />
          </Stack>
        )}
        {Boolean(value) && (
          <Box>
            <Card sx={{ p: 2, width: 300, position: 'fixed', bottom: 20, right: 20 }}>
              <Stack justifyContent={'center'} textAlign={'center'} spacing={1}>
                <Typography variant="subtitle1">{value.description}</Typography>

                {isServiceAvailable(value.description) ? (
                  <Button
                    variant="contained"
                    onClick={async () => {
                      const userAddress = {
                        userAddress: value.description,
                        userLatitude: newUserAddress.current.latitude,
                        userLongitude: newUserAddress.current.longitude,
                        country: newUserAddress.current.country || '',
                        postalCode: newUserAddress.current.postalCode || '',
                        city: newUserAddress.current.city || '',
                        street: newUserAddress.current.street || '',
                        houseNumber: newUserAddress.current.houseNumber || '',
                      };
                      setAddressInfo(userAddress);
                      // eslint-disable-next-line no-unused-expressions
                      props?.setUserAddress && props?.setUserAddress(userAddress);
                      if (user) {
                        const userRef = doc(collection(firestore, 'users'), user.phone || user.email);
                        const userDoc = await getDoc(userRef);
                        if (userDoc.exists()) {
                          await updateDoc(userRef, {
                            userAddress: value.description,
                            userLatitude: latitude,
                            userLongitude: longitude,
                          });
                        }
                      }

                      handleClose();
                    }}>
                    Confirm Address
                  </Button>
                ) : (
                  <Button variant="contained" onClick={() => setNotFound(true)} color="error">
                    Service not available
                  </Button>
                )}
              </Stack>
            </Card>
          </Box>
        )}
        <Marker
          position={{
            lat: latitude,
            lng: longitude,
          }}
          options={options}
          draggable={showField}
          onDragEnd={handleMarkerDragEnd}
        />
        {showField && (
          <>
            {permanentMarkerPosition.map((cord, i) => (
              <Marker
                key={i}
                position={cord}
                icon={{
                  // url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(markerIcon)}`,
                  url: `/assets/diazo_location_marker.png`,
                  scaledSize: new window.google.maps.Size(24, 24),
                }}
              />
            ))}
          </>
        )}
      </GoogleMap>
      <UnavailableDialog open={notFound} handleClose={() => setNotFound(false)} />
    </>
  );
}
export default ErrorBoundry(GoogleMaps);
