import { restaurantTimings } from './constants/DiazoOutletInfo';

const getTimings = restaurantName => {
  const currentTime = new Date();
  const dayOfWeek = currentTime.getDay();
  const timings = restaurantTimings[restaurantName];
  if (!timings) {
    return 'Invalid restaurant name';
  }
  const isWeekend = dayOfWeek === 5 || dayOfWeek === 6; // Updated weekend check
  const { start, end } = isWeekend ? timings.weekends : timings.weekdays;
  return `${start} - ${end}`;
};

const getClosingTime = restaurantName => {
  if (!restaurantName) return '';
  const currentTime = new Date();
  const dayOfWeek = currentTime.getDay();
  const timings = restaurantTimings[restaurantName];
  if (!timings) {
    return 'Invalid restaurant name';
  }
  const isWeekend = dayOfWeek === 5 || dayOfWeek === 6; // Updated weekend check
  const { end } = isWeekend ? timings.weekends : timings.weekdays;
  return end;
};

// Helper function to convert 24-hour format to 12-hour format with AM/PM
const convertTo12HourFormat = time => {
  const [hour, minute] = time.split(':');
  const hours = parseInt(hour, 10);
  // eslint-disable-next-line
  const minutes = minute ? minute : '00';
  const period = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;
  return `${formattedHours}:${minutes}${period}`;
};

const isRestaurantOpen = restaurantName => {
  // Get current time in Berlin timezone
  const berlinTime = new Date().toLocaleString('en-US', { timeZone: 'Europe/Berlin' });
  const currentTime = new Date(berlinTime);
  const day = currentTime.getDay();
  const hour = currentTime.getHours();
  const minute = currentTime.getMinutes();
  const timings = restaurantTimings[restaurantName];
  if (!timings) return false;
  const isWeekend = day === 5 || day === 6;
  const { start, end } = isWeekend ? timings.weekends : timings.weekdays;

  // Convert start and end time strings to hours and minutes
  const [startHour, startMinute] = start.split(':').map(Number);
  const [endHour, endMinute] = end.replace('AM', '').split(':').map(Number);

  // Convert start and end times to absolute minutes
  const startTimeInMinutes = startHour * 60 + startMinute;
  const endTimeInMinutes = (endHour + (end.includes('AM') ? 24 : 0)) * 60 + endMinute;
  const currentTimeInMinutes = hour * 60 + minute;
  // Check if current time is within the range
  if (startTimeInMinutes <= endTimeInMinutes) {
    // Same day range
    return currentTimeInMinutes >= startTimeInMinutes && currentTimeInMinutes < endTimeInMinutes;
  }
  return currentTimeInMinutes >= startTimeInMinutes || currentTimeInMinutes < endTimeInMinutes;
};

// Updated functions to convert times if needed
const getRestaurantTimings = restaurantName => {
  const timings = getTimings(restaurantName);
  if (timings === 'Invalid restaurant name') return timings;
  const [start, end] = timings.split(' - ');
  return `${convertTo12HourFormat(start)} - ${convertTo12HourFormat(end)}`;
};

const getRestaurantClosingTime = restaurantName => {
  const closingTime = getClosingTime(restaurantName);
  if (closingTime) return convertTo12HourFormat(closingTime);
  return closingTime;
};

export { getRestaurantTimings, getRestaurantClosingTime, isRestaurantOpen };
