export const DiazoOutletInfo = [
  {
    name: 'Diazo-Wedding',
    key: 'Diazo - Wedding',
    phoneNumber:"+49 157 72329128",
    searchAbleAddress: 'Reinickendorfer Str. 5, 13347 Berlin',
    placeId: 'ChIJicuO0StSqEcRgb5NdT1OQIo',
    lat: 52.548636901812095,
    lng: 13.208540196539603,
    postalCodes: ['13347', '13353', '13351', '13349', '13357', '13355', '13359', '13409', '10115', '13407', '10557'],
  },
  {
    name: 'Diazo-Neukolin',
    key: 'Diazo - Neukölln',
    phoneNumber:"+49 176 74633014",
    placeId: 'ChIJ72zSpuxPqEcRWmP-Guq67Fo',
    searchAbleAddress: 'Silbersteinstraße 36, 12051 Berlin',
    lat: 52.4671824,
    lng: 13.4366736,
    postalCodes: [
      '12051',
      '12049',
      '12053',
      '12055',
      '12043',
      '12045',
      '12047',
      '12059',
      '12055',
      '12057',
      '12347',
      '12349',
      '12437',
      '12359',
      '12351',
      '12353',
      '12349',
      '12305',
      '12307',
      '12309',
      '12459',
      '12439',
      '12487',
      '12355',
      '12357',
      '12489',
      '12524',
      '12555',
      '12526',
      '12557',
    ],
  },
  {
    name: 'Diazo-Spandau',
    key: 'Diazo - Spandau',
    phoneNumber:"+49 1577 8813623",
    placeId: 'ChIJxVnS5chXqEcRb4T8SuoSuxc',
    searchAbleAddress: 'Kurstraße 22, 13585 Berlin',
    lat: 52.483846655492634,
    lng: 13.361593879135514,
    postalCodes: [
      '13585',
      '13583',
      '13589',
      '13587',
      '13599',
      '13597',
      '13581',
      '13591',
      '13593',
      '13595',
      '14089',
      '13505',
      '13629',
      '14053',
    ],
  },
  {
    name: 'Diazo-Prenzlauerberg',
    key: 'Diazo - Prenzlauerberg',
    phoneNumber:"+49 157 72329128",
    placeId: 'ChIJ2eQ1uodTqEcRyHeMj5JaVPM',
    searchAbleAddress: 'Greifenhagener Str. 61, 10437 Berlin',
    lat: 52.5460261,
    lng: 13.4142707,
    postalCodes: [
      '10437',
      '10439',
      '10435',
      '10405',
      '10409',
      '13189',
      '13086',
      '13088',
      '10407',
      '10119',
      '10178',
      '10117',
      '13129',
      '13127',
      '13125',
    ],
  },
  {
    name: 'Diazo-Schoneberg',
    phoneNumber:"+49 1573 5871274",
    searchAbleAddress: 'Leberstraße 25, 10829 Berlin',
    key: 'Diazo - Schöneberg',
    placeId: 'ChIJD3nd_tdRqEcR1-3MHKgd7PE',
    lat: 52.4671824,
    lng: 13.4366736,
    postalCodes: [
      '10829',
      '12101',
      '10827',
      '10823',
      '10965',
      '10781',
      '10783',
      '10961',
      '12159',
      '10785',
      '10779',
      '10777',
      '10963',
      '10823',
      '12103',
      '10715',
      '10717',
      '10777',
      '10789',
      '12105',
      '12169',
      '12161',
      '14197',
      '10719',
      '10707',
      '12163',
      '12099',
      '12109',
      '12165',
      '12167',
      '12247',
      '12107',
      '14199',
      '12203',
      '12249',
      '10709',
      '12209',
      '12207',
      '12205',
      '14195',
      '12277',
      '12279',
      '14167',
      '10711',
      '14193',
      '14169',
      '14165',
      '14163',
      '10969',
      '10865',
      '10967',
      '12157',
      '14129',
      '14109',
    ],
  },
  {
    name: 'Diazo-Moabit',
    phoneNumber:"030 36411168",
    searchAbleAddress: 'Huttenstraße 22, 10553 Berlin, Germany',
    key: 'Diazo - Moabit',
    placeId: 'ChIJRz3FeRVRqEcRZYiRf9YhvVI',
    lat: 52.5285525,
    lng: 13.3184901,
    postalCodes: [
      '10553',
      '10589',
      '13627',
      '10551',
      '10555',
      '10559',
      '10587',
      '14059',
      '10585',
      '10625',
      '10623',
      '14059',
      '14052',
      '14057',
      '10787',
      '10627',
      '10629',
      '14055',
    ],
  },
  {
    name: 'Diazo-Friedrichshain',
    phoneNumber:"+49 157 39808250",
    searchAbleAddress: 'Petenkofferstr 6, 10247 Berlin',
    key: 'Diazo - Friedrichshain',
    placeId: 'ChIJ6UqwnotOqEcRZ94_5Se8lF0',
    lat: 52.516484,
    lng: 13.4700468,
    postalCodes: [
      '10247',
      '10243',
      '10249',
      '10369',
      '10367',
      '10365',
      '10245',
      '10317',
      '13055',
      '12685',
      '10997',
      '10999',
      '10315',
      '10319',
      '10318',
      '12435',
      '13053',
      '13051',
      '13059',
      '13057',
      '10179',
      '12681',
      '12683',
      '12621',
      '12619',
      '12679',
      '12689',
      '12687',
      '12629',
      '12627',
      '12623',
    ],
  },
  {
    name: 'Diazo-Wittenau',
    phoneNumber:"0175 955 2995",
    searchAbleAddress: 'Oranienburger Str. 257, 13437 Berlin, Germany',
    key: 'Diazo - Wittenau',
    placeId: 'ChIJv3RuZZpTqEcR-rm5UjgUMr4',
    lat: 52.5845603,
    lng: 13.3318753,
    postalCodes: [
      '13437',
      '13403',
      '13503',
      '13405',
      '13435',
      '13439',
      '13158',
      '13156',
      '13187',
      '13503',
      '13467',
      '13469',
      '13465',
      '13159',
      '13507',
    ],
  },
];

const postalCodes = {
  Wedding: DiazoOutletInfo[0].postalCodes,
  Neukolin: DiazoOutletInfo[1].postalCodes,
  Spandau: DiazoOutletInfo[2].postalCodes,
  Prenzlauerberg: DiazoOutletInfo[3].postalCodes,
  Schoneberg: DiazoOutletInfo[4].postalCodes,
  Moabit: DiazoOutletInfo[5].postalCodes,
  Friedrichshain: DiazoOutletInfo[6].postalCodes,
  Wittenau: DiazoOutletInfo[7].postalCodes,
};

export const diazoWeddingAreas = postalCodes.Wedding;
export const diazoPrenzlauerbergAreas = postalCodes.Prenzlauerberg;
export const diazoNeukolinAreas = postalCodes.Neukolin;
export const diazoSchonebergAreas = postalCodes.Schoneberg;
export const diazoSpandauAreas = postalCodes.Spandau;
export const diazoMoabitAreas = postalCodes.Moabit;
export const diazoFriedrichshainAreas = postalCodes.Friedrichshain;
export const diazoWittenauAreas = postalCodes.Wittenau;
export const diazoLocations = DiazoOutletInfo.flatMap(store => store.postalCodes);

export const restaurantTimings = {
  'Diazo-Wedding': {
    weekdays: { start: '17:00', end: '06:00' },
    weekends: { start: '17:00', end: '06:00' },
  },
  'Diazo-Neukolin': {
    weekdays: { start: '11:00', end: '04:00' },
    weekends: { start: '11:00', end: '04:00' },
  },
  'Diazo-Spandau': {
    weekdays: { start: '17:00', end: '04:00' },
    weekends: { start: '17:00', end: '04:00' },
  },
  'Diazo-Prenzlauerberg': {
    weekdays: { start: '11:00', end: '06:00' },
    weekends: { start: '11:00', end: '06:00' },
  },
  'Diazo-Schoneberg': {
    weekdays: { start: '11:00', end: '04:00AM' },
    weekends: { start: '11:00', end: '06:00AM' },
  },
  'Diazo-Moabit': {
    weekdays: { start: '11:00', end: '04:00' },
    weekends: { start: '11:00', end: '06:00' },
  },
  'Diazo-Friedrichshain': {
    weekdays: { start: '11:00', end: '04:00' },
    weekends: { start: '11:00', end: '06:00' },
  },
  'Diazo-Wittenau': {
    weekdays: { start: '15:00', end: '04:00' },
    weekends: { start: '15:00', end: '06:00' },
  },
};
