import { Slide, Stack } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { BannerContext } from '../../context/bannerProvider';
import useResponsive from '../../hooks/useResponsive';
import MenuDialog from '../dialog/MenuDialog';
import Iconify from '../iconify/Iconify';

function ResponsiveAppBar() {
  const [state, setState] = React.useState(false);
  const navigate = useNavigate();
  const { hideBanner, setHideBanner } = React.useContext(BannerContext);
  const [isIphone, setIsIphone] = React.useState(false);
  const isMobile = useResponsive('down', 'md');

  React.useEffect(() => {
    setIsIphone(navigator.oscpu?.includes('iOS') || navigator.oscpu?.includes('macOS'));
  }, []);
  const toggleDrawer = () => setState(!state);
  function HideOnScroll(props) {
    const { children } = props;
    // const trigger = useScrollTrigger({
    //   target: window ? window() : undefined,
    // });

    return (
      <Slide appear={false} direction="down" in={!!true}>
        {children}
      </Slide>
    );
  }

  return (
    <>
      <HideOnScroll>
        <AppBar
          elevation={0}
          position="static"
          sx={{ zIndex: theme => theme.zIndex.drawer + 1, backgroundColor: '#fff' }}>
          {!hideBanner && (
            <Box
              sx={{
                backgroundColor: theme => theme.palette.common.black,
                zIndex: 9999,
                display: { xs: 'flex' },
                justifyContent: { xs: 'space-between', lg: 'center' },
                textAlign: 'center',
                alignItems: 'center',
                color: theme => theme.palette.common.white,
                py: 1,
              }}>
              {isMobile ? (
                <>
                  <Box
                    component={'img'}
                    src="/favicon/favicon.jpg"
                    alt="logo"
                    sx={{ objectFit: 'cover', height: 45, width: { xs: 45, md: 100 } }}
                  />
                  <Box>
                    <Typography variant="subtitle1">Essen bestellen</Typography>
                    <Typography variant="subtitle2" color={theme => theme.palette.grey[300]}>
                      in der DIAZO-APP öffnen
                    </Typography>
                  </Box>

                  <Box display={'flex'} gap={{ md: 2, xs: 0 }}>
                    <Button
                      size="small"
                      sx={{ borderRadius: '26px' }}
                      variant="contained"
                      onClick={
                        () =>
                          window.open(
                            isIphone
                              ? 'https://apps.apple.com/us/app/diazo/id6448659489'
                              : 'https://play.google.com/store/apps/datasafety?id=com.diazo.app&hl=en_ZA',
                            '_blank'
                          )
                        // https://qrfy.com/p/VqE_jqq9Tn?utm_source=qrcode&utm_medium=app&utm_campaign=22999758
                      }>
                      Download
                    </Button>
                    <IconButton
                      onClick={() => {
                        sessionStorage.setItem('hideBanner', true);
                        setHideBanner(true);
                      }}>
                      <Iconify icon={'octicon:x-24'} />
                    </IconButton>
                  </Box>
                </>
              ) : (
                <Box display={'flex'} gap={{ md: 2, xs: 0 }} alignItems={'center'} sx={{ height: 20 }}>
                  <Typography variant="subtitle2">30 € Gutschein im Checkout. GUTSCHEINCODE: SSV344 </Typography>
                  <IconButton
                    onClick={() => {
                      sessionStorage.setItem('hideBanner', true);
                      setHideBanner(true);
                    }}>
                    <Iconify icon={'octicon:x-24'} />
                  </IconButton>
                </Box>
              )}
            </Box>
          )}

          <Box className="flex justify-between py-2 md:py-3">
            <Box
              onClick={() => {
                if (window.location.pathname !== '/') navigate(-1);
              }}
              className="flex w-full items-center justify-start pl-4">
              <span className="text-black">{window.location.pathname !== '/' && <IoIosArrowBack size={20} />}</span>
              <Typography
                variant={'h3'}
                noWrap
                onClick={() => navigate('/')}
                className="underline-offset-[3px]"
                sx={{
                  'color': theme => theme.palette.primary.main,
                  'textDecoration': 'underline',
                  'fontFamily': 'Times New Roman, Times, serif',
                  'textDecorationThickness': 2,
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}>
                DIAZO
              </Typography>
            </Box>
            <Box className="flex w-full items-center justify-end gap-3">
              <button
                type="button"
                onClick={() => navigate('/jobsposting/welcome')}
                className="mr-2 hidden items-center gap-2 font-bold text-black md:flex">
                <img src="/assets/images/icons/jobs.svg" alt="" className="h-5 w-5" />
                Fahrer*in werden
              </button>
              <Box display={'flex'} alignItems={'center'}>
                <IconButton aria-label="delete" sx={{ display: { xs: 'none', sm: 'flex' } }}>
                  <Iconify icon={'emojione:flag-for-germany'} width={20} />
                </IconButton>
              </Box>
              <Stack className="flex items-center justify-end" sx={{ color: theme => theme.palette.primary.main }}>
                <IconButton onClick={toggleDrawer} color="primary">
                  <Iconify icon={'mingcute:menu-fill'} />
                </IconButton>{' '}
              </Stack>
            </Box>
          </Box>
        </AppBar>
      </HideOnScroll>
      <MenuDialog open={state} toggleDialog={() => setState(prev => !prev)} />
    </>
  );
}
export default ResponsiveAppBar;
